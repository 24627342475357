import CV from "../assets/cv.pdf";
import { Link } from "react-scroll";

function CTA() {
  return (
    <div className="cta">
      <a href={CV} download className="btn">
        Download CV
      </a>
      <Link to="contact" className="btn btn-primary">
        Message me
      </Link>
    </div>
  );
}

export default CTA;
